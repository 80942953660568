$blockHeights: 50px, 100px, 130px, 200px;
$rowHeights: 20px, 25px, 30px;
$radiuses: 3px, 4px, 5px, 10px, 20px, 30px;

app-skeleton {
  [class^="sk-"] {
    width: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
    margin-bottom: 15px;
    border-radius: 4px;

    @each $radius in $radiuses {
      &.radius-#{$radius} {
        border-radius: $radius;
      }
    }
  }

  .sk-row {
    height: 30px;
  }

  .sk-line {
    height: 16px;
    margin-bottom: 3px;
  }

  @each $rowHeight in $rowHeights {
    .sk-row-#{$rowHeight} {
      height: $rowHeight;
    }
  }

  @each $blockHeight in $blockHeights {
    .sk-block-#{$blockHeight} {
      height: $blockHeight;
      border-radius: 10px;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(202, 19%, 89%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
