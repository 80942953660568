.mat-mdc-slide-toggle {
  button.mdc-switch {
    width: 40px;

    &:not(.mdc-switch--selected) {
      .mdc-switch__handle-track {
        transform: translateX(4px);
      }
    }

    .mdc-switch__ripple,
    .mdc-switch__shadow {
      display: none;
    }

    .mdc-switch__track {
      height: 24px;
      border-radius: 12px;
      border: solid 2px $border-color;
      box-sizing: border-box;

      &::before {
        display: none;
      }

      &::after {
        border: 0;
        padding: 0;
        background: transparent !important;
      }
    }

    .mdc-switch__handle {
      height: 16px;
      width: 16px;
      background-color: $border-color;
      box-sizing: border-box;

      &::before {
        display: none;
      }

      &::after {
        background: $border-color !important;
      }
    }

    &.mdc-switch--selected:enabled {
      .mdc-switch__track {
        border-color: var(--Myrtle-green, #287271) !important;
        background-color: var(--Myrtle-green, #287271) !important;

        &::after {
          background-color: var(
            --Myrtle-green,
            #287271
          ) !important;
        }
      }

      .mdc-switch__handle {
        &::after {
          background: white !important;
        }
      }
    }

    .mdc-switch__icons {
      display: none;
    }
  }

  label.mdc-label {
    @include font(600, 14px);
    padding-left: 10px;
  }
}
