@import "/src/core/styles/theme";

.ql-toolbar.ql-snow {
  border: 2px solid $border-color;
  border-radius: 12px 12px 0 0;
}

.editor .ql-container.ql-snow {
  border: 2px solid $border-color;
  border-radius: 0 0 12px 12px;
}

.ql-snow .ql-tooltip {
  z-index: 3;
  box-shadow: none;
  border-radius: 6px;
}