@import "/src/core/styles/globals";

.time-picker {
  &.filled {
    .mdc-notched-outline {
      background-color: var(--Jungle-green-10, #eaf5f4);
      border-radius: 10px;
    }

    .mat-mdc-notch-piece.mdc-notched-outline__leading,
    .mat-mdc-notch-piece.mdc-notched-outline__trailing,
    .mat-mdc-notch-piece.mdc-notched-outline__notch {
      border: 0;
    }
  }

  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      height: 36px;
      padding: 5px 0;
      width: 32px;
    }

    .mat-mdc-button-touch-target {
      height: 36px;
      width: 32px;
    }
  }

  .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined {
      .mdc-floating-label {
        font-size: 14px;
      }

      .mat-mdc-form-field-infix {
        width: auto;
        min-height: 36px;
        z-index: 1;
      }

      .mdc-text-field__input:not(textarea) {
        width: 50px;
        min-height: 36px;

        &::placeholder {
          font-size: 14px !important;
        }
      }

      .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-24px) scale(0.75);
      }
    }

    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 18px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix {
    padding-left: 0;
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    height: 26px;
    width: 26px;
    top: 5px;
    left: 3px;
  }
}
